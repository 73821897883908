.gif-holder{
    height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
}
.giphy-img{
    width: 180px;
    margin-bottom: 10px;
    cursor: pointer;
}
.giphy-img img{
    width: 100%;
}

.image-size-fixed{
    height: 180px;
    object-fit: cover;
}