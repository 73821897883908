.site-documents-area{
    padding: 150px 0 100px;
}

.site-documents-area * {
    font-family: "Montserrat Alternates" !important;
}

/* .site-documents-area a{
    color: var(--secondary-clr);
}

.site-documents-area h1{
    margin-bottom: 30px;
}

.site-documents-area h4{
    margin-top: 20px;
    margin-bottom: 25px;
}

.site-documents-area p{
    margin-top: 0;
    margin-bottom: 15px;
}

.site-documents-area ul {
    margin-top: 10px;
}

.site-documents-area ul li{
    margin-bottom: 15px;
}

.site-documents-area ul > li::marker {
    font-weight: bold;
} */