.top_navbar {
    display: none !important;
}

.sidebar-container {
    width: 200px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f8f9fa;
    overflow-x: hidden;
    padding-top: 50px;
}

.sidebar-container .navbar-brand {
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 50px;
}

.sidebar-container .nav-link {
    padding-left: 20px;
}

.sidebar-container .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.sidebar-container .navbar-dark .navbar-nav .nav-link:hover,
.sidebar-container .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff;
}

.sidebar-container .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
    background-color: #007bff;
}

.sidebar-container .navbar-dark .navbar-nav .nav-link.active:hover,
.sidebar-container .navbar-dark .navbar-nav .nav-link.active:focus {
    background-color: #007bff;
}

.sidebar-container .navbar-dark .navbar-nav .nav-item .nav-link {
    padding-right: 2rem;
    padding-left: 2rem;
}

.profile_sidebar.Show {
    transform: translateX(0);
}

a:hover {
    color: var(--secondary-clr);
}

/* .close-icon {
    position: absolute;
    right: 20px;
    top: 16px;
    height: 30px;
    width: 30px;
    background: #f75252b8;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.action-row {
    display: flex;
    align-items: center;
    column-gap: 15px;
    row-gap: 15px;
    justify-content: flex-end;
}

.action-btn {
    background: none;
    border: none;
    padding: 0;
    font-size: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50%;
}

.action-btn.edit {
    background: var(--primary-clr);
}

.action-btn.delete {
    background: red;
}

.action-btn:hover {
    color: #fff;
}

.wrapper .section {
    width: calc(100% - 300px);
    margin-left: 300px;
    transition: all 0.5s ease;
}

.wrapper .section .top_navbar {
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 55%);
    /* height: 50px; */
    display: flex;
    align-items: center;
    padding: 10px 30px;
    margin-bottom: 30px;
}

.wrapper .section .top_navbar .hamburger a {
    font-size: 28px;
    color: var(--secondary-clr);
}

.wrapper .section .top_navbar .hamburger a:hover {
    color: var(--secondary-clr);
}

.wrapper .section .content-wrapper {
    /* margin: 30px; */
    background: #fff;
    padding: 40px;
    line-height: 28px;
}

body.Sidebar_Active .wrapper .sidebar {
    left: -225px;
}

body.Sidebar_Active .wrapper .section {
    margin-left: 0;
    width: 100%;
}

.home_btn a {
    color: #fff;
    text-decoration: none;
}

.home_btn a i {
    padding-right: 5px;
}

.home_btn {
    padding: 12px 18px;
    min-width: auto;
    background-color: var(--secondary-clr);
    color: #ffffff;
    border-color: var(--secondary-clr);
    font-size: 16px;
}

.account-setting .modal-form {
    width: 48%;
    margin-right: 15px;
}

.account-box-design {
    background-color: #fff;
    box-shadow: #00000029 0 1px 4px;
    border-radius: 4px;
    padding: 30px;
    margin: 20px 0;
}

hr.horizontal_line {
    margin: 25px 0 10px;
    color: #727171;
}

.profile_heading_design i {
    color: var(--secondary-clr);
}

form.account-form {
    margin-right: -15px;
}

.account-setting .modal-form input {
    font-size: 14px;
    border: 1px solid #dfd9d9;
}

.account-setting .modal-form input:focus {
    border: 1px solid var(--secondary-clr);
}

.user_toggle .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    border-radius: 50px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    transition: .4s;
}

.user_toggle .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    border-radius: 50px;
}

.user_toggle input:checked+.slider:before {
    transform: translateX(20px);
}

.notification_text span {
    font-weight: 600;
}

.notification_text p {
    font-size: 15px;
}

.account-box-design .modal-form {
    width: 48%;
}

.change_psw .modal-form input {
    font-size: 14px;
    border: 1px solid #dfd9d9;
}

.change_psw .modal-form input:focus {
    border: 1px solid var(--secondary-clr);
}

.form-select.modal-form {
    border-color: #dddddd;
    padding: 10px;
}

.wishboard_btn {
    padding: 7px 18px;
}

.form-select.modal-form:focus {
    border-color: var(--secondary-clr);
}

thead.table_header {
    background-color: #ddd;
}

li.breadcrumb-item.active {
    color: var(--secondary-clr);
}

.heading-icon {
    width: 50px;
}

.wishboard_icon {
    width: 45px;
}

.heading_board {
    font-size: 25px;
    font-weight: 600;
}

a.details_link {
    color: var(--secondary-clr);
    padding-left: 10px;
}

.profile_list img {
    width: 50px;
}

.icon-box-design {
    background: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 15px;
}

button.btn-dark.home_btn.wishboard_btn {
    background-color: #000;
    border: 1px solid #000;
}

.form-check.form-check-inline input {
    margin-right: 12px;
}

[type=button]:disabled,
[type=reset]:disabled,
[type=submit]:disabled,
button:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    opacity: 0.5;
}

.card-text-pra {
    font-size: 14px;
}

p.date {
    font-size: 14px;
    margin-bottom: 0;
}

.table-btn {
    padding: 3px 8px;
}

.menu-div {
    color: #000;
    text-decoration: none;
}
.group-imageview{
    width: 150px;
}
@media screen and (max-width:575px) {
    .top_navbar {
        display: block !important;
    }

    .hamburger {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .wrapper .section .content-wrapper {
        padding: 15px;
    }

    .home_btn {
        padding: 6px 14px;
        font-size: 14px;
    }

    .account-box-design {
        padding: 15px;
    }

    .account-box-design .modal-form {
        width: 100%;
    }

    .account-setting h4 {
        font-size: 18px;
        margin-top: 35px;
    }

    .order-flex {
        flex-wrap: wrap;
    }

    .order-flex .modal-form {
        margin-bottom: 10px;
    }

    thead.table_header {
        font-size: 14px;
    }

    .icon-box-design {
        margin-bottom: 15px;
    }

    .wrapper .section {
        width: auto;
        margin-left: 0;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .top_navbar {
        display: block !important;
    }

    .hamburger {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .wrapper .section .content-wrapper {
        padding: 15px;
    }

    .home_btn {
        padding: 6px 14px;
        font-size: 14px;
    }

    .account-box-design {
        padding: 15px;
    }

    .account-box-design .modal-form {
        width: 100%;
    }

    .account-setting h4 {
        font-size: 18px;
    }

    .order-flex {
        flex-wrap: wrap;
    }

    .order-flex .modal-form {
        margin-bottom: 10px;
    }

    thead.table_header {
        font-size: 14px;
    }

    .icon-box-design {
        margin-bottom: 15px;
    }

    .wrapper .section {
        width: auto;
        margin-left: 0;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .account-box-design .modal-form {
        width: 100%;
    }

    .order-flex {
        flex-wrap: wrap;
    }

    .order-flex .modal-form {
        margin-bottom: 10px;
        width: 47%;
    }

    thead.table_header {
        font-size: 14px;
    }

    .icon-box-design {
        margin-bottom: 15px;
    }

    .wrapper .section {
        width: auto;
        margin-left: 0;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .account-box-design .modal-form {
        width: 47%;
    }

    .order-flex {
        flex-wrap: wrap;
    }

    .order-flex .modal-form {
        margin-bottom: 10px;
        width: 48%;
    }
}


.change-close {
    position: absolute;
    top: 20px;
    right: 20px;
}