/* create card step  */
.create-card-step .container-fluid {
    padding: 0;
}
.create-card-step{
    padding: 150px 0 100px;
}
.step-flow{
	text-align: center;
	width: 25%;
	position: relative;
	display: block;
}
.step-flow::before {
    content: '';
    display: block;
    height: 3px;
    width: calc(100% - 40px);
    background-color: var(--primary-clr);
    top: 13px;
    position: absolute;
    left: calc(50% + 20px);
}

.step-flow span {
    cursor: pointer;
}
.step-flow:last-child:before {
    display: none;
}
.step-flow .is-complete{
	display: block;
	position: relative;
	border-radius: 50%;
	height: 30px;
	width: 30px;
    color: #fff;
	border: 0px solid #AFAFAF;
	background-color: var(--primary-clr);
	margin: 0 auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
	z-index: 2;
}
.step-text-flow {
    background-color: var(--primary-clr);
    padding: 10px;
    position: relative;
}
.step-text-flow p {
    color: #fff;
    margin: 0;
}
.text-step{
    position: relative;
    z-index: 3;
    cursor: pointer;
}

.text-step:after {
    position: absolute;
    bottom: 0;
    left: 30%;
    margin-left: -6px;
    content: "";
    display: block;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid var(--primary-clr);
    z-index: -1;
    transition: all .3s ease-in-out;
}
.text-step.active:after{
    bottom: -28px;
}

.create-card {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 4px 8px 0 rgb(0 0 0 / 25%);
    border-radius: 10px;
    height: 100%;
}
.modal-form label {
    color: var(--dark-text);
    font-weight: 600;
}
.craete-card-details{
    background-color: #fff;
    padding: 40px 50px;
    box-shadow: 0px 4px 8px 0 rgb(0 0 0 / 25%);
    border-radius: 10px;
}
.craete-card-details h5{
    color: var(--dark-text);
    font-size: 25px;
}
.create-card img{
    width: 100%;
}
.modal-form textarea{
    width: 200px;
    height: 250px;
}
.modal-form textarea {
    width: 350px;
    height: 270px;
    font-size: 15px;
    padding: 20px 15px;
    border-color: #ddd;
    resize: none;
}
.d-hidden{
visibility: hidden !important;
pointer-events: none !important;
}
.massage_btn {
    text-align: center;
}
button.btn-danger {
    background: none;
    color: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
}
button.btn-danger.plus {
width: fit-content !important;
min-width: unset !important;
padding: 5px 10px !important;
}
.btn-danger.active{
    background-color: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
}
.page-preview-btn span {
    width: 35px;
    height: 35px;
    background-color: #fff;
    border: 1px solid var(--secondary-clr);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.page-preview-btn span i{
    color: var(--primary-clr);
    
}
label.form-check-label {
    color: var(--dark-text);
    font-weight: 600;
    margin-right: 20px;
}
p.login-link {
    color: var(--dark-text);
    font-weight: 600;
}
p.login-link a {
    color: var(--secondary-clr);
}
.login-text{
    color: var(--secondary-clr);
    text-decoration: underline;
    width: fit-content;
    margin: 0;
    cursor: pointer;
}
.pricing-toggle-btn{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}
.pricing-toggle-btn h5 {
    color: var(--dark-text);
    font-size: 30px;
}
.form-check-input:checked {
    background-color:var(--secondary-clr) ;
    border-color:var(--secondary-clr) ;
}
.form-check-input:focus {
    border-color: var(--secondary-clr);
    box-shadow: 0 0 0 0.25rem rgb(247 82 82 / 25%);
}
.form-select {
    font-size: 13px;
    border-color: #ddd;
}
.form-select:focus {
    border-color: var(--secondary-clr);
    box-shadow: 0 0 0 .25rem var(--secondary-clr)(13,110,253,.25);
}

@media screen and (max-width:575px) {
    .step-flow .is-complete {
        height: 25px;
        width: 25px;
        font-size: 12px;
    }
    .step-text-flow p {
        font-size: 8px;
        width: 25%;
        text-align: center;
    }
    .text-step.active:after {
        bottom: -19px;
    }
    .text-step:after {
        left: 46%;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid var(--primary-clr);
    }
    .modal-form textarea {
        width: 250px;
        height: 200px;
        font-size: 14px;
        padding: 15px 10px;
    }
    .craete-card-details {
        padding: 20px;
        margin-top: 25px;
    }
    .craete-card-details h5 {
        font-size: 20px;
    }
    .page-preview-btn span {
        width: 30px;
        height: 30px;
    }
    .page-preview-btn span i {
        font-size: 12px;
    }
}
@media (min-width:576px) and (max-width:767px) {
    .step-flow .is-complete {
        height: 25px;
        width: 25px;
        font-size: 12px;
    }
    .step-text-flow p {
        font-size: 12px;
        width: 25%;
        text-align: center;
    }
    .text-step.active:after {
        bottom: -19px;
    }
    .text-step:after {
        left: 46%;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid var(--primary-clr);
    }
    .modal-form textarea {
        width: 260px;
        height: 220px;
        font-size: 14px;
        padding: 15px 10px;
    }
    .craete-card-details {
        padding: 20px;
        margin-top: 25px;
    }
    .craete-card-details h5 {
        font-size: 20px;
    }
    .page-preview-btn span {
        width: 30px;
        height: 30px;
    }
    .page-preview-btn span i {
        font-size: 12px;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .craete-card-details {
        padding: 20px;
    }
    .modal-form textarea {
        width: 292px;
        height: 230px;
    }
}
@media (min-width:992px) and (max-width:1199px) {
    .craete-card-details {
        padding: 30px;
    }
    .modal-form textarea {
        width: 260px;
        height: 275px;
    }
}
@media screen and (max-width:400px) {
    .link-btn {
        justify-content: center !important;
    }
}

.add-image-holder{
    width: 100%;
    height: 100%;
}

.reqired-star{
    display: inline-block;
    color: red;
}

.form-tooltip-btn{
    border-radius: 50%;
    background: var(--primary-clr);
    color: #fff;
    font-size: 10px;
    width: 15px;
    height: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}