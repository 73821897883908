.banner-area {
    position: relative;
    padding: 150px 0 150px;
    background: var(--primary-light);
    z-index: 3;
}

.banner-area.with-slide {
    padding: 100px 0 100px;
}

.banner-area h1 {
    font-size: 44px;
}

.header-menu .navbar {
    transition: all .3s ease-in-out;
    padding: 18px 0;
}

.header-menu .navbar.bg-white {
    border-bottom: 1px solid #e7e7e7;
    /* box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1); */
}

.header-menu .nav-link {
    margin-right: var(--bs-navbar-nav-link-padding-x);
    margin-left: var(--bs-navbar-nav-link-padding-x);
}

.header-menu .nav-link {
    font-weight: 600;
    position: relative;
    z-index: 2;
    padding: 0 !important;
}

.header-menu .nav-link:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    z-index: -1;
    background: var(--secondary-clr);
    transition: all .3s ease-in-out;
}

.header-menu .nav-link:hover:after,
.header-menu .nav-link.active:after {
    width: 100%;
}

.header-menu .nav-link:hover,
.header-menu .nav-link.active:hover {
    color: #74757E;
}

.header-menu .header-btn {
    color: #000000;
    padding: 10px 15px;
}

.header-menu .header-btn:hover {
    color: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);

}

.header-menu .header-btn.outline {
    color: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
}

.header-menu .header-btn.outline:hover {
    color: #fff;
    background: var(--secondary-clr);
}

/* dropdown menu  */
.menu-profile-dropdown button:active {
    background-color: transparent !important;
    color: var(--primary-clr) !important;
    border: none !important;

}

.menu-profile-dropdown .dropdown-item {
    border-left: 4px solid transparent;
    font-size: 15px;
}

.dropdown-toggle::after {
    margin-left: 12px !important;
    vertical-align: .255em;
    content: "";
    border-top: .4em solid;
    border-right: .4em solid transparent;
    border-bottom: 0;
    border-left: .4em solid transparent;
}

.menu-profile-dropdown .dropdown-item:active {
    background-color: transparent !important;
    color: #212529 !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: transparent;
    border-left: 4px solid var(--secondary-clr);
}

button.btn:focus {
    box-shadow: none !important;
    outline: 0 !important;
}

.icon-area {
    font-size: 23px;
    border: 3px solid var(--primary-clr);
    padding: 7px 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

h1 {
    font-size: 54px;
    color: var(--dark-text);
}

.banner-img {
    text-align: center;
    position: relative;
    z-index: 1;
}

.right-shade-img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    pointer-events: none;
}

.btn:active {
    color: #fff !important;
    background-color: var(--secondary-clr) !important;
    border-color: var(--secondary-clr) !important;
}

.btn-primary {
    padding: 12px 10px;
    min-width: 173px;
    background-color: var(--secondary-clr);
    color: #ffffff;
    border-color: var(--secondary-clr);
    font-size: 16px;
}

.btn-primary.outline {
    background-color: #fff;
    color: var(--dark-text);
    border: 1px solid var(--secondary-clr);
    border-radius: 8px;
}

.btn-primary.dark {
    background: var(--dark-text);
    color: #fff;
    border-color: var(--dark-text);
}

.btn-primary.outline:hover {
    color: #fff;
}

.btn-primary:hover {
    background-color: var(--secondary-clr);
    border-color: var(--secondary-clr);
}

.header-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

img.left-banner-shape {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    pointer-events: none;
}

p {
    font-size: 18px;
    color: #74757E;
}

.banner-label {
    position: absolute;
    top: -155px;
    right: 27%;
    width: 32%;
}

.banner-back-bg {
    position: absolute;
    right: 14%;
    top: -18%;
    z-index: -1;
    object-fit: contain;
    width: 369px;
    max-width: unset;
}

@media (max-width: 767px) {
    .banner-back-bg {
        position: absolute;
        right: 16%;
        top: -42%;
        z-index: -1;
        object-fit: contain;
        width: 63%;
        max-width: unset;
    }

}

.title-bottom-line {
    position: relative;
}

.title-bottom-line::after {
    content: '';
    position: absolute;
    bottom: 13px;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--primary-clr);
}

/* Section Title */
.section-title-area h2 {
    font-size: 38px;
    position: relative;
    color: var(--dark-text);
}

.section-title-area h2 .heading-design:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    z-index: -1;
    background: #FFC477;
    transition: all .3s ease-in-out;
}

.section-title-area.light-style h2 {
    color: #fff;
}

.section-title-area.light-style p {
    color: #fff;
    opacity: 0.8;
}

/* Category Card section */

.category-card-listing div[class^="col-"]:nth-child(2n + 1) .category-card {
    background: var(--primary-light);
}

.category-card-listing div[class^="col-"]:nth-child(2n + 2) .category-card {
    background: var(--secondary-light);
}

.invitation-area {
    padding: 80px 0;
}

.category-card-listing .card-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 0.01em;
    color: #151F35;
}

.card-link {
    color: var(--secondary-clr);
    text-decoration: none;
    font-weight: 600;
    transition: all .3s ease-in-out;
}

.card-link:hover {
    color: var(--secondary-clr);
}

/* wishboard card section  */
.wishboard-area {
    padding: 80px 0;
}

.wishboard-card.card {
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.09));
}

.wishboard-card.card .card-title {
    font-size: 15px;
    color: #74757E;
}

.section-title-area .title-design:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    z-index: -1;
    background: var(--secondary-clr);
    transition: all .3s ease-in-out;
}

.btn-secondary {
    padding: 12px 10px;
    min-width: 173px;
    background-color: var(--primary-clr);
    color: #ffffff;
    border-color: var(--primary-clr);
    font-size: 16px;
}

.btn-secondary:hover {
    background-color: var(--primary-clr);
    border-color: var(--primary-clr);
}

.wishboard-list span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 100%;
    background-color: var(--primary-clr);
    color: var(--primary-light);
}

.wishboard-list p {
    color: var(--dark-text);
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: 600;
}

/* card work section  */
.cardwork-area {
    margin-top: 80px;
    margin-bottom: 80px;
}

.cardwork-list {
    position: relative;
    background: #fff;
    overflow: hidden;
    transition: all .3s ease-in-out;
    z-index: 3;
}

.cardwork-list .card-pattern {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -3;
    opacity: 0;
    transform: translateY(100%);
    transition: all .4s ease-in-out;
}

.cardwork-list.card {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    height: 100%;
}

.cardwork-list .card-title {
    font-size: 24px;
    font-weight: 600;
    color: var(--dark-text);
}

.cardwork-list .inner-icon {
    color: var(--primary-clr);
}

.cardwork-list .card-body {
    padding: 40px;
}

.cardwork-list:hover {
    background: var(--primary-clr);
}

.cardwork-list:hover .inner-icon {
    color: #fff;
}

.cardwork-list:hover .card-title {
    color: #fff;
}

.cardwork-list:hover .card-text {
    color: #fff;
    opacity: 0.8;
}

.cardwork-list:hover .card-pattern {
    transform: translateY(0);
    opacity: 0.5;
}

/* Feature Card */

.features-card {
    text-align: center;
    padding: 25px;
    padding-top: 40px;
    border: 3px solid transparent;
    background: #fff;
    border-radius: 30px;
    height: 100%;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
    transition: all .3s ease-in-out;
    cursor: pointer;
}

.features-card:hover {
    border-color: var(--secondary-clr);
    transform: translateY(-10px);
}

.features-card h3 {
    margin-bottom: 20px;
}

/* .features-card-list div[class*="col-"]:nth-child(4n+1) .features-card {
    border-top-color: var(--secondary-clr);
}

.features-card-list div[class*="col-"]:nth-child(4n+2) .features-card {
    border-top-color: var(--primary-clr);
}

.features-card-list div[class*="col-"]:nth-child(4n+3) .features-card {
    border-top-color: #5cbf4b;
}

.features-card-list div[class*="col-"]:nth-child(4n+4) .features-card {
    border-top-color: #24d7be;
} */

/* Latestblog-area */
span.title-design {
    z-index: 2;
}

.latestblog-area {
    margin: 0px 0 80px;
    background-color: var(--dark-text);
    padding: 80px 0;
}

.latestblog-area .section-title-area {
    color: #fff;
}

.latestblog-area .section-title-area h2 {
    color: #fff;
}

.latestblog-area .section-title-area p {
    color: var(--primary-light);
}

.blog-image-wrap {
    padding: 10px 10px 0;
}
.top-bar-bt{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.top-bar-bt img.auther-img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.top-bar-bt .auther-area span{
    font-weight: 600;
    font-size: 13px;
    margin-left: 5px;
}
.category-card img {
    height: 200px;
    object-fit: cover;
}
.latestblog-area .card-title {
    font-size: 24px;
    font-weight: 600;
}
.latestblog-area .category-card img {
    height: 270px;
    object-fit: cover;
}

/* testimonial section  */
.testimonial-area {
    margin-bottom: 80px;
}

.testimonial-card.card {
    transition: all .3s ease-in-out;
}

.testimonial-card.card {
    /* box-shadow: 0px 4px 20px rgba(116, 117, 126, 0.18); */
    border: 1px solid #ececec !important;
}

.testimonial-card.card:hover {
    box-shadow: 0px 8px 30px rgba(116, 117, 126, 0.38);
}

.testimonial-card.card img {
    width: 50px;
}

.testimonial-card .card-text {
    font-size: 15px;
}

.testimonial-slider {
    padding-bottom: 50px;
    padding-top: 30px;
}

.client-img {
    width: 55px;
    height: 55px;
    border-radius: 100%;
}

.client-name h5 {
    font-size: 18px;
    color: var(--primary-clr);
}

.client-name p {
    font-size: 14px;
    color: var(--primary-clr);
}

/* advertisebanner section  */
.advertisebanner-area {
    padding: 50px 0;
    background-color: #F7FAFD;
}

.advertisebanner-area .banner-inner {
    padding: 80px 0;
    background: var(--primary-clr);
    width: 100%;
    position: relative;
    overflow: hidden;
    z-index: 3;
    display: flex;
    align-items: center;
}

.advertisebanner-area .banner-inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: -1;
}

/* footer  */
footer {
    background-color: #fff;
    padding: 50px 20px 0;
    border-top: 1px solid #ececec;
    /* box-shadow: 0 0 100px rgba(0, 0, 0, 0.1); */
}

.copyright-row {
    /* margin-top: 30px; */
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
}

.payment-accept {
    text-align: right;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
}
.payment-accept .stripe{
    display: flex;
    height: 40px;
}
.payment-accept img {
    height: 35px;
}

.resource-link h5 {
    font-size: 18px;
    position: relative;
    color: var(--dark-text);
}

.resource-link a {
    text-decoration: none;
    color: #74757e;
    font-size: 16px;
    padding-bottom: 10px;
    transition: all 0.5s;
}

.resource-link a:hover {
    color: var(--secondary-clr);
}

.resource-link h5::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    background-color: var(--secondary-clr);
    height: 2px;
    box-sizing: border-box;
    width: 50px;
}


/* sign in/up  modal */
.sign-in-modal {
    padding: 50px;
}

.sign-in-modal iframe {
    width: 100%;
}

.modal-form label {
    color: var(--light-text);
}

.modal-form input {
    font-size: 12px;
    padding: 10px;
    border: 1px solid #dfd9d9;
}

.modal-form input:focus {
    box-shadow: none;
    border-color: var(--secondary-clr);
}

.modal-form a,
.account-link {
    color: #000;
    text-decoration: none;
}

.sign-up-sec .outline-btn {
    color: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
}

.sign-up-form {
    display: flex;
    flex-wrap: wrap;
}

.facebook-login-btn {
    background: #e7ffdd!important;

    border-radius: 5px;
    height: 40px;
    text-align: center;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 100%;
}

.facebook-login-btn i {
    margin-right: 10px;
    font-size: 20px;
}

.facebook-login-btn svg {
    width: 26px;
    height: 22px;
    margin-right: 12px;
}

.google-login-btn {
    width: 100%;
    background: #e7ffdd!important;
    border-radius: 5px;
    color: #4267b2;
    height: 40px;
    text-align: center;
    border: 1px solid #ccc !important;
    font-size: 14px;
    margin-bottom: 10px;
    justify-content: center;
    box-shadow: none !important;
}

.google-login-btn>div {
    padding: 6px !important;
    background: #e7ffdd!important;
}

.google-login-btn svg {
    width: 20px;
    margin-right: 10px;
    height: 20px;
}

.form-label {
    margin-bottom: 0.5rem;
    font-size: 13px;
}

/*end sign in/up modal*/

.girl-image {
    width: auto !important;
}

.menu-profile-dropdown .btn {
    color: var(--bs-nav-link-color);
    text-decoration: none;
    box-shadow: none;

}

.menu-profile-dropdown .btn:focus,
.menu-profile-dropdown .btn:focus-visible,
.menu-profile-dropdown .btn:active {
    box-shadow: none;
}

.menu-profile-dropdown .btn i {
    margin-right: 10px;
}

/* blog page  */
.blog-details-area {
    padding-top: 100px;
    margin: 70px 0;
}

.blog_details .page-title {
    text-align: left;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 15px;
}

.blog_details p {
    font-size: 16px;
}

.related_sticky_blog {
    position: sticky;
    top: 150px;
    height: 100%;
}

.releted_bolg_title {
    font-size: 25px;
    color: var(--secondary-clr);
}

.releted_items_about {
    height: 798px;
    overflow-y: auto;
    position: relative;
}

.releted_items_about_inner {
    display: flex;
    padding: 20px 0;
    align-items: stretch;
    position: relative;
}

.releted_cnt_inner {
    width: 100%;
    height: 90px;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
}

.set_txt {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.date-time {
    font-size: 15px;
    color: #893b36;
    display: block;
}

.date-time {
    padding-right: 10px;
}

.releted_items_cnt a {
    color: var(--secondary-clr);
    font-weight: 500;
    text-decoration: none;
}

.releted_cnt_inner p {
    font-size: 16px;
}

.set_txt a {
    color: #000 !important;
    font-weight: 600;
}

/* Invite section  */
.invites_area {
    margin-top: 150px;
}

.heading_text {
    border: 1px solid #ddd;
    padding: 10px;
    background: var(--primary-clr);
    color: #fff;
}

.heading_text h4 {
    font-size: 20px;
}

.sub_text_area {
    border: 1px solid #ddd;
    border-top: 0;
    padding: 20px;
}

.sub_text_area h4 {
    font-size: 17px;
}

p.sub_text_link {
    font-size: 16px;
    color: #000;
}

.static_sec_area {
    margin: 50px 0;
}

.copy-text {
    position: relative;
    padding: 5px 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin: 30px 0 0;
}

.qr-style svg {
    width: 100%;
}

.copied-text {
    text-align: right;
    color: green;
}

.copy-text input.text {
    font-size: 16px;
    color: #555;
    border: none;
    outline: none;
    flex: 1;
}

.copy-text button {
    padding: 3px 8px;
    background: var(--primary-clr);
    color: #fff;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
}

.copy_text h5 {
    font-size: 16px;
}

.copy_text p {
    font-size: 14px;
}

.static_text_area {
    height: 100%;
}

.copytext-area {
    padding: 15px;
    border: 1px solid #ddd;
    height: 100%;
}

.holdtext {
    display: none;
}

/* newcard  */
.create_card_area {
    padding-top: 110px;
}

button.group_btn {
    background: transparent;
    color: #000;
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 14px;
}

.card_bg {
    background-color: #faebd73d;
    padding: 15px 0;
    border-top: 1px solid rgb(221 221 221 / 20%);
}

.heading_card_bg {
    padding: 15px 0;
    background-color: #5f705ce3;
}

.birthday_name h3 {
    font-size: 20px;
    color: #fff;
}

.birthday_name p {
    color: #fff;
}

.card_design {
    box-shadow: 7px 7px 18px #0000002e;
    width: 100%;
    display: inline-block;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 15px;
}

.card_img img {
    height: 260px;
    display: inline-block;
    width: 100%;
    border-radius: 10px 10px 0 0;
}

.card_bg_img {
    /* background-image: url('../../assets/img/card-bg.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.9;
    padding: 80px 0;
}

.card-bg-image-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
}

.close_btn button {
    position: absolute;
    top: 15px;
    left: -12px;
    border: 0;
    border-radius: 5px;
    background: var(--secondary-clr);
    color: #fff;
    padding: 2px 10px 4px;
}

.whyus-area {
    padding: 80px 0;
    background: #FCF9F3;
}

.feature-area {
    padding: 60px 0;
    background: #ebf5e7;
}

.light-grey-wave-top {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -60px;
    transform: rotate(180deg);
    z-index: -1;
}

.empty-card .card-icon {
    color: var(--secondary-clr);
    font-size: 40px;
    margin-bottom: 15px;
}

.empty-card p {
    margin-bottom: 0;
}

.empty-card .card-icon svg {
    fill: var(--secondary-clr);
}
.auther-box{
    width: 100%;
    display: flex;     
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
}
.auther-box .auther-description{
    flex: 1;
}
.auther-box .h5{
    display: block;
}
.auther-box img{
    width: 60px;
    height: 60px;
    margin-right: 20px;
}
.latestblog-card-listing .swiper-pagination{
    position: static;
    margin-top: 20px;
}
.latestblog-card-listing  .swiper-pagination-bullet{
    background: #fff;
    opacity: 0.5;
}
.latestblog-card-listing  .swiper-pagination-bullet-active{
    background: var(--secondary-clr);
    opacity: 1;
}
@media screen and (max-width:991px) {
    .latestblog-card-listing .swiper-pagination{
    margin-top: 0;
        
    }
    /* header responsive  */
    .nav-bar {
        position: absolute;
        width: 250px;
        height: calc(100vh);
        background: #fff;
        top: 0px;
        left: -100%;
        transition: 0.5s;
        padding: 30px 15px;
    }

    .nav-bar.show {
        left: 0;
    }

    .header-menu .nav-link {
        padding: 10px 0 0px !important;
    }

    .logo_sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .close-area {
        position: absolute;
        top: 12px;
        right: 20px;
        background: var(--secondary-clr);
        width: 30px;
        height: 30px;
        border-radius: 50px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    .menu-profile-dropdown.dropdown {
        padding-top: 20px;
    }

    ul.navbar-nav.responsive-nav {
        text-align: left !important;
    }

    .header-menu .header-btn {
        color: #00000096;
        padding: 10px 6px 0;
        font-weight: 600;
        font-size: 16px;
    }

    .header-menu .header-btn:hover,
    .header-menu .header-btn.outline:hover {
        color: #00000096;
        border: none;
        background-color: transparent;
    }

    .header-menu .header-btn.outline {
        color: #00000096;
        padding: 10px 6px 0;
        font-weight: 600;
        font-size: 16px;
        border: 0;
    }

    button.btn.header-btn:active {
        background-color: transparent !important;
        color: #00000096 !important;
        border: none !important;
    }

    .btn:active {
        color: #fff !important;
        background-color: var(--secondary-clr) !important;
        border-color: var(--secondary-clr) !important;
    }

    .dropdown-toggle::after {
        margin-left: 12px !important;
        vertical-align: middle;
        content: "";
        font-family: "Font Awesome 6 Free";
        border-top: none;
        border-bottom: 0;
        border-left: noneq;
        font-size: 39px;
        transform: rotate(90deg);
    }

    .menu-profile-dropdown .btn {
        color: #00000096 !important;
        padding-left: 7px;
        font-weight: 600;
        width: 100%;
        height: 40px;
    }

    .menu-profile-dropdown.dropdown {
        padding-top: 4px;
    }

    .account-name {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        width: 100%;
    }

    .menu-profile-dropdown .dropdown-item {
        padding-left: 13px;
        color: var(--primary-clr);
        font-weight: 500;
    }

    .navbar-nav .dropdown-menu {
        border: 0;
        margin: 0;
        padding: 0;
    }
}

@media screen and (max-width:575px) {

    .advertisebanner-area .banner-inner img.girl-image {
        display: none;
    }

    .advertisebanner-area .banner-inner {
        flex-direction: column-reverse;
        padding: 40px 20px 40px;
    }

    /* slider section  */
    p {
        font-size: 15px;
        color: #74757E;
    }

    .slider-title-area h1 {
        font-size: 28px;
    }

    .btn-primary {
        padding: 8px 15px;
        min-width: auto;
        font-size: 14px;
    }

    .banner-title-design {
        margin-bottom: 30px;
    }

    .banner-label {
        top: -17%;
        width: 50%;
    }

    .banner-area {
        padding: 130px 0 40px;
    }

    /* invitation section  */
    .invitation-area {
        padding: 50px 0;
    }

    .section-title-area h2 {
        font-size: 25px;
        position: relative;
    }

    .category-card.card {
        margin-bottom: 20px;
    }

    .category-card-listing .card-title {
        font-size: 16px;
    }

    /* wishboard section  */
    .wishboard-area {
        padding: 0 0 50px 0;
    }

    .wishboard-text-area {
        margin-top: 25px;
    }

    .wishboard-list span {
        width: 25px;
        height: 25px;
    }

    .wishboard-list p {
        font-size: 15px;
    }

    .btn-secondary {
        padding: 8px 15px;
        font-size: 14px;
    }

    /* cardwork section  */
    .cardwork-area {
    margin-top: 50px;
    margin-bottom: 50px;
    }

    .cardwork-list.card {
        height: auto;
    }

    .cardwork-list {
        margin-bottom: 20px;
    }

    .cardwork-list .card-body {
        padding: 20px;
    }

    .cardwork-list .card-title {
        font-size: 18px;
        margin-bottom: 9px !important;
    }

    /* latestblog section  */
    .latestblog-area {
        margin: 0px 0 50px;
        background-color: var(--dark-text);
        padding: 50px 0;
    }

    .latestblog-area .section-title-area {
        display: block !important;
    }

    .latestblog-area .card-title {
        font-size: 18px;
    }

    /* testimonial section  */
    .testimonial-area {
        margin-bottom: 50px;
    }

    .client-name p {
        font-size: 12px;
    }

    .client-name h5 {
        font-size: 16px;
    }

    .testimonial-slider {
        padding-bottom: 0px;
        padding-top: 30px;
    }

    /* footer sec  */
    footer {
        padding: 35px 0 0;
    }

    .resource-link h5 {
        margin-top: 15px;
    }

    .resource-link a {
        font-size: 14px;
        padding-bottom: 8px;
    }

    /* blog page  */
    .releted_items_img {
        margin: 0 auto;
        text-align: center;
        margin-bottom: 10px;
    }

    .releted_bolg_title {
        margin-top: 20px;
    }

    .blog_details .page-title {
        font-size: 18px;
        line-height: 25px;
    }

    /* invites page  */
    .copy_text_area .heading_text h4 {
        font-size: 16px;
    }

    .sub_text_area h4 {
        font-size: 14px;
    }

    p.sub_text_link {
        font-size: 13px;
    }

    .copy-text {
        margin: 20px 0;
    }

    .copytext-area {
        height: auto;
        margin-top: 20px;
    }

    .static_sec_area {
        margin: 30px 0 50px;
    }

    .copy-img img {
        width: 45px;
        height: 45px;
    }

    /* new card  */
    .card_design {
        margin-bottom: 15px;
    }

    button.group_btn {
        padding: 7px;
        font-size: 12px;
        margin-bottom: 10px;
    }

    .card_img img {
        height: 220px;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .advertisebanner-area .banner-inner img.girl-image {
        width: 350px !important;
        object-fit: contain;
        position: static;
    }

    /* slider section  */
    p {
        font-size: 15px;
        color: #74757E;
    }

    .slider-title-area h1 {
        font-size: 28px;
    }

    .btn-primary {
        padding: 8px 15px;
        min-width: auto;
        font-size: 14px;
    }

    .banner-label {
        top: -17%;
        width: 50%;
    }

    .banner-area {
        padding: 110px 0 40px;
    }

    /* invitation section  */
    .invitation-area {
        padding: 50px 0;
    }

    .section-title-area h2 {
        font-size: 25px;
        position: relative;
    }


    .category-card.card {
        margin-bottom: 20px;
    }

    .category-card-listing .card-title {
        font-size: 16px;
    }

    .cardwork-list.card {
        height: auto;
    }

    .category-item {
        width: 50%;
        margin: 0 auto;
    }

    /* wishboard section  */
    .wishboard-area {
        padding: 0 0 50px 0;
    }

    .wishboard-text-area {
        margin-top: 25px;
    }

    .wishboard-list span {
        width: 25px;
        height: 25px;
    }

    .wishboard-list p {
        font-size: 15px;
    }

    .btn-secondary {
        padding: 8px 15px;
        font-size: 14px;
    }

    /* cardwork section  */
    .cardwork-area {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .cardwork-list {
        margin-bottom: 20px;
    }

    .cardwork-list .card-body {
        padding: 20px;
    }

    .cardwork-list .card-title {
        font-size: 18px;
        margin-bottom: 9px !important;
    }

    /* latestblog section  */
    .latestblog-area {
        margin: 0px 0 50px;
        background-color: var(--dark-text);
        padding: 50px 0;
    }

    .latestblog-area .section-title-area {
        display: block !important;
    }

    .latestblog-area .card-title {
        font-size: 18px;
    }

    /* testimonial section  */
    .testimonial-area {
        margin-bottom: 50px;
    }

    .client-name p {
        font-size: 12px;
    }

    .client-name h5 {
        font-size: 16px;
    }

    /* footer sec  */
    footer {
        padding: 35px 0 0;
    }

    .resource-link h5 {
        margin-top: 15px;
    }

    .resource-link a {
        font-size: 14px;
        padding-bottom: 8px;
    }

    .advertisebanner-area .banner-inner {
        padding: 40px 0;
    }

    /* blog page  */
    .releted_bolg_title {
        margin-top: 20px;
    }

    .blog_details .page-title {
        font-size: 18px;
        line-height: 25px;
    }

    /* invites page  */
    .copy_text_area .heading_text h4 {
        font-size: 16px;
    }

    .sub_text_area h4 {
        font-size: 14px;
    }

    p.sub_text_link {
        font-size: 13px;
    }

    .copy-text {
        margin: 20px 0;
    }

    .static_sec_area {
        margin: 30px 0 50px;
    }

    .copy-img img {
        width: 45px;
        height: 45px;
    }

    /* new card  */
    .card_design {
        margin-bottom: 15px;
    }

    button.group_btn {
        padding: 7px;
        font-size: 12px;
        margin-bottom: 10px;
    }

    .card_img img {
        height: 220px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .advertisebanner-area .banner-inner img.girl-image {
        width: 350px !important;
        object-fit: contain;
        position: absolute;
        bottom: 0;
        object-position: bottom;
    }

    .advertisebanner-area .banner-inner {
        padding: 40px 0;
    }

    /* slider section  */
    p {
        font-size: 15px;
        color: #74757E;
    }

    .slider-title-area h1 {
        font-size: 30px;
    }

    .btn-primary {
        padding: 8px 15px;
        min-width: auto;
        font-size: 14px;
    }

    .banner-label {
        top: -17%;
        width: 50%;
    }

    .banner-area {
        padding: 130px 0 40px;
    }

    /* invitation section  */
    .invitation-area {
        padding: 50px 0;
    }

    .section-title-area h2 {
        font-size: 25px;
        position: relative;
    }


    .category-card.card {
        margin-bottom: 20px;
    }

    .category-card-listing .card-title {
        font-size: 16px;
    }

    /* wishboard section  */
    .wishboard-area {
        padding: 0 0 50px 0;
    }

    .wishboard-text-area {
        margin-top: 0px;
    }

    .wishboard-list span {
        width: 25px;
        height: 25px;
    }

    .wishboard-list p {
        font-size: 15px;
    }

    .btn-secondary {
        padding: 8px 15px;
        font-size: 14px;
    }

    /* cardwork section  */
    .cardwork-area {

        margin-top: 50px;
        margin-bottom: 50px;
    }

    .cardwork-list {
        margin-bottom: 20px;
    }

    .cardwork-list .card-body {
        padding: 20px;
    }

    .cardwork-list .card-title {
        font-size: 18px;
        margin-bottom: 9px !important;
    }

    /* latestblog section  */
    .latestblog-area {
        margin: 0px 0 50px;
        background-color: var(--dark-text);
        padding: 50px 0;
    }

    .latestblog-area .card-title {
        font-size: 18px;
    }

    /* testimonial section  */
    .testimonial-area {
        margin-bottom: 50px;
    }

    .client-name p {
        font-size: 12px;
    }

    .client-name h5 {
        font-size: 16px;
    }

    /* footer sec  */
    footer {
        padding: 35px 0 0;
    }

    .resource-link h5 {
        margin-top: 15px;
    }

    .resource-link a {
        font-size: 14px;
        padding-bottom: 8px;
    }

    /* blog page  */
    .releted_bolg_title {
        margin-top: 20px;
    }

    /* invites page  */
    .copy_text_area .heading_text h4 {
        font-size: 18px;
    }

    .sub_text_area h4 {
        font-size: 14px;
    }

    p.sub_text_link {
        font-size: 13px;
    }

    .copy-text {
        margin: 20px 0;
    }

    .static_sec_area {
        margin: 30px 0 50px;
    }

    .copy-img img {
        width: 55px;
        height: 55px;
    }

    /* new card  */
    .card_img img {
        height: 210px;
    }
}

@media (min-width:992px) and (max-width:1200px) {

    /* slider section  */
    p {
        font-size: 17px;
    }

    .slider-title-area h1 {
        font-size: 35px;
    }

    .banner-label {
        top: -22%;
        width: 50%;
    }

    .banner-area {
        padding: 180px 0 40px;
    }

    /* invitation section  */
    .section-title-area h2 {
        font-size: 30px;
    }


    .category-card.card {
        margin-bottom: 20px;
    }

    .category-card-listing .card-title {
        font-size: 18px;
    }

    /* wishboard section  */
    .wishboard-area {
        padding: 0 0 80px 0;
    }

    .wishboard-text-area {
        margin-top: 0px;
    }

    .wishboard-list span {
        width: 30px;
        height: 30px;
    }

    .wishboard-list p {
        font-size: 17px;
    }

    /* cardwork section  */
    .cardwork-list {
        margin-bottom: 20px;
    }

    .cardwork-list .card-body {
        padding: 20px;
    }

    .cardwork-list .card-title {
        font-size: 18px;
        margin-bottom: 9px !important;
    }

    /* latestblog section  */
    .latestblog-area {
        padding: 50px 0;
    }

    .latestblog-area .card-title {
        font-size: 18px;
    }

    /* testimonial section  */
    .client-name p {
        font-size: 12px;
    }

    .client-name h5 {
        font-size: 16px;
    }

    /* footer sec  */
    .resource-link h5 {
        margin-top: 15px;
    }

    .resource-link a {
        font-size: 14px;
        padding-bottom: 8px;
    }

    /* new card  */
    .card_img img {
        height: 230px;
    }
}
@media (max-width: 767px) {
   
    .title-bottom-line::after {
        bottom: 5px;
    }
}