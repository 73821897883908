.cropper-modal {
  background-color: gray !important;
  opacity: 0.5 !important;
}
.file-input {
  color: var(--zdfontcolor);
}
.info-text {
  font-size: var(--info1);
  font-family: var(--fontFamilyBold);
  color: var(--zdfontcolor);
  text-align: center;
}
.crop-button {
  float: left !important;
}
.image-cropper-box {
  height: 100%;
width: 100%;
}
.image-cropper-box >div, .image-cropper-box >div img{
  width: 100%;
  height: 100%;
}