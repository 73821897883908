.main-category-area {
    padding: 200px 0 100px;
}

.main-cateogory-card {
    background: var(--primary-light);
    overflow: hidden;
    text-decoration: none !important;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.main-cateogory-card:hover {
    /* box-shadow: 0 1rem 1rem rgba(0,0,0,0.1); */
}
.main-cateogory-card:hover img {
    transform: scale(1.08) rotate(3deg);
}
.category-img-bx{
    width: 100%;
    position: relative;
    overflow: hidden;
}
.main-cateogory-card img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    transition: all .3s ease-in-out;
}

.imageBlock{
    overflow: hidden;
}
.main-cateogory-card h4 {
    background: var(--primary-clr);
    padding: 10px 15px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    text-align: center;
    margin: 0;
}

@media screen and (max-width:575px) {
    .main-category-area {
        padding: 140px 0 80px;
    }
    .main-category-area .section-title-area h2 {
        font-size: 24px;
    }
}
@media (min-width:576px) and (max-width:767px) {
    .main-category-area {
        padding: 140px 0 80px;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .main-category-area {
        padding: 160px 0 80px;
    }
}