.blog-card{
    /* box-shadow: 0 1rem 3rem rgba(0,0,0,0.1); */
    height: 100%;
    border: 1px solid #ececec !important;
}
.blog-card img {
    height: 270px;
    object-fit: cover;
}

.blog-card .card-title{
    color: #000;
}
.blog-card .card-text {
    font-size: 14px;
}