section.category-listing-area {
    margin: 150px 0 80px;
}
.category-listing-details {
    background: #fff;
    border: 1px solid rgb(0 0 0 / 50%);
    padding: 30px 20px;
    position: sticky;
    top: 125px;
}
.category-heading h4 {
    color: var(--secondary-clr);
    font-size: 30px;
    position: relative;
}
.category-heading h4::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: var(--primary-clr);
    height: 2px;
    box-sizing: border-box;
    width: 100px;
}
.category-listing-details .accordion-item{
    border: 0;
}
.category-listing-details .accordion-button {
    padding-bottom: 5px;
    padding-right: 0;
    padding-left: 0;
}
.accordion-button::after {
    flex-shrink: 0;
    margin-left: auto;
    content: "+";
    font-size: 22px;
    background-image:none;
}
.accordion-button:focus {
    box-shadow: none;
}
.accordion-button:not(.collapsed)::after {
    content: '-';
    font-size: 35px;
    background-image: none;
    transform: none;
    display: flex;
    align-items: center;
}
.accordion-button:not(.collapsed) {
    color: var(--secondary-clr);
    background-color: transparent;
    box-shadow: none;
    outline: none;
}
button:focus:focus-visible {
    box-shadow: 0;
}
.category-listing-details .accordion-body{
    padding-top: 0;
    padding-bottom: 0;
}
li.breadcrumb-item a {
    text-decoration: none;
    color: var(--dark-text);
}
.category-listing-details .list-group-item {
    border: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
}
.category-listing-details .list-group-item a {
    text-decoration: none;
    color: var(--dark-text);
    font-size: 15px;
    padding-bottom: 5px;
}
/* right section  */
.card-design-area h5 {
    font-size: 38px;
    font-weight: 700;
    color: var(--dark-text);
}
.card-design {
    margin-top: 25px;
}
.upload-box {
    width: 100%;
    height: 270px;
    border: 2px dashed #000;
    cursor: pointer;
}
.upload-box.upload-card-img img{
    width: unset;
    height: unset;
    object-fit: cover;
}
.card-design {
    margin-right: -50px;
}
.upload-card-img {
    /* margin-right: 15px; */
    height: 270px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    box-shadow: 0 1rem 3rem rgba(0,0,0,0.1);
}
.upload-card-img.wish-card{
    height: 300px;
}
.upload-card-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.upload-card-img svg {
    position: absolute;
    right: 10px;
    top: 14px;
    z-index: 99;
}
.overlay-img {
    position: absolute;
    background-color: rgba(95, 112, 92,0.5);
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    color: var(--primary-light);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
}
.overlay-img h5 {
    font-size: 15px;
    text-align: center;
}
.upload-card-img:hover .overlay-img {
    opacity: 1;
}
.pro-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    
    /* background-color: var(--primary-clr); */
    display: flex;
    align-items: center;
    justify-content: center;
}
.pro-icon svg {
    text-align: center;
    width: 25px;
    height: 25px;
    position: static;
}

@media screen and (max-width:575px) {
    .category-heading h4 {
        font-size: 25px;
    }
    .card-design-area h5 {
        font-size: 28px;
        margin-top: 30px;
    }
    .card-design {
        margin-right: 0;
    }
}
@media (min-width:576px) and (max-width:767px) {
    .card-design {
        margin-right: 0;
    }
    .category-heading h4 {
        font-size: 25px;
    }
    .card-design-area h5 {
        font-size: 28px;
        margin-top: 30px;
    }
    .upload-card-img {
        margin-right: 26px;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .card-design-area h5 {
        font-size: 30px;
        margin-top: 30px;
    }
}

.serch-product-area{
    display: flex;
    align-items: center;
}
.serch-product-area .search-wrapper{
    display: flex;
    flex: 1;
}
.serch-product-area .search-wrapper .icon-btn{
    width: 40px;
    height: 40px;
    border: 0;
    background: var(--secondary-clr);
    color: #fff;
}
.serch-product-area .search-wrapper .form-control,
.serch-product-area .filter-box select {
    height: 40px;
    border-radius: 0;
}
.serch-product-area .search-wrapper input:focus {
    border-color: var(--secondary-clr);
    flex: 1;
    box-shadow: none;
}

.serch-product-area .filter-box{
    display: flex; 
    align-items: center;
    margin-left: 20px;
}