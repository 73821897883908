.faq-area {
    padding: 150px 0px;
}
.hash-listing{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: sticky;
    top: 120px;
    /* box-shadow: 0 1rem 3rem rgba(0,0,0,0.1); */

}
.hash-listing a {
    width: 100%;
    background: #fff;
    padding: 10px 20px;
    color: #000;
    text-decoration: none;
    border-bottom: 1px solid #efefef;
}

.hash-section{
    padding: 30px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    /* box-shadow: 0 1rem 2rem rgba(0,0,0,0.1); */
    margin-bottom: 30px;
}
.hash-section h2{
    font-weight: 400;
    font-size: 18px;
    line-height: 1.8rem;
    margin-bottom: 20px;

}
.hash-section h4{
    font-size: 18px;
    font-weight: 600;

}
.hash-section p{
    font-size: 14px;
    font-weight: 400;
}

.search-fq-input {
    line-height: 2.5;
}
.search-fq-input:focus {
    border-color: var(--secondary-clr);
    box-shadow: none;
}