.sign-card-area {
    position: relative;
    padding: 150px 0 100px;
    background: #f5f5f5;
    overflow: hidden;
}

.flip-book-area {
    display: flex;
    justify-content: center;
    padding: 50px 0;
}

.flip-book {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    margin: 0 auto;
    display: none;
    z-index: 100;
}

.emailbox-logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 150px;
}

.giphy-attr img {
    width: 144px;
}

.giphy-attr {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.tickit-img {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 120px;
}

.html-book {
    background-size: cover;
}

.page {
    padding: 20px;
    background-color: #fdfaf7;
    color: #785e3a;
    border: solid 1px #dddddd;
    overflow: hidden;
}

.page .page-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.page .page-content .page-header {
    height: 30px;
    font-size: 100%;
    text-transform: uppercase;
    text-align: center;
}

.page .page-content .page-image {
    height: 100%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.page .page-content .page-text {
    height: 100%;
    flex-grow: 1;
    font-size: 80%;
    text-align: justify;
    margin-top: 10px;
    padding-top: 10px;
    box-sizing: border-box;
    border-top: solid 1px #f4e8d7;
}

.page .page-content .page-footer {
    height: 30px;
    border-top: solid 1px #f4e8d7;
    font-size: 80%;
    color: #998466;
}

.page.--left {
    border-right: 0;
    box-shadow: inset -7px 0 30px -7px rgba(252, 121, 121, 0.2);
}

.page.--right {
    border-left: 0;
    box-shadow: inset 7px 0 30px -7px rgba(252, 121, 121, 0.2);
}

.page.--right .page-footer {
    text-align: right;
}

.page.hard {
    background-color: #f2e8d9;
    border: solid 1px #998466;
}

.page.page-cover {
    background-color: #e3d0b5;
    color: #785e3a;
    border: solid 1px #998466;
    padding: 0;
}

.page.page-cover h2 {
    text-align: center;
    padding-top: 50%;
    font-size: 210%;
}

.card-box-ui {
    width: 100%;
}

.card-box-ui img {
    width: 100%;
    height: auto;
    object-fit: fill;
}

.book-product-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.page.page-cover.page-cover-top {
    box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
}

.page.page-cover.page-cover-bottom {
    box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), 10px 0 8px 0px rgba(0, 0, 0, 0.4);
}

.book-btn {
    width: 50px;
    height: 50px;
    font-size: 20px;
    border-radius: 50%;
    border: 1px solid var(--secondary-clr);
    color: var(--secondary-clr);
}

.navigation-area {
    display: flex;
    justify-content: center;
    column-gap: 20px;
}

.logo-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom-tag-line {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
}

.bottom-tag-line p {
    color: #998466;
    font-size: 12px;
    margin: 0;

}

.bottom-tag-line span {
    font-size: 10px;
    color: var(--secondary-clr);
}




.mail-wrapper-box {
    height: 550px;
}

.letter-image {
    transform: rotate(-90deg) translate(92px, 92px);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.pdf-book{
    width: 100%;
}



/* Envenlope Open animations */
@keyframes new {
    0% {
        left: 0%;
        margin-left: -550px;
    }

    100% {
        left: 50%;
        margin-left: -133px;
    }
}

@keyframes open {
    0% {
        transform: translate3d(0, 0, 0) rotateY(0);
    }

    33.333% {
        transform: translate3d(-100%, 0, 0) rotateY(-180deg);
    }

    66.666% {
        transform: translate3d(-100%, 0, 0) rotateY(-180deg);
    }

    100% {
        transform: translate3d(-100%, 733.33333px, 0) rotateY(-180deg);
    }
}

@keyframes flap-open {
    0% {
        transform: rotateX(0deg);
        z-index: 5;
    }

    50% {
        transform: rotateX(0deg);
        z-index: 5;
    }

    100% {
        transform: rotateX(-180deg);
        z-index: -1;
    }
}

@keyframes letter-out {
    0% {
        transform: translate3d(0, 0, 0);
        box-shadow: inset 0 0px 30px -5px #b08c5b, 0 0 10px -5px;
    }

    50% {
        transform: translate3d(0, -733.33333px, 0);
        box-shadow: inset 0 0px 30px -5px #a87e50, 0 0 20px -5px;
    }

    100% {
        transform: translate3d(0, -733.33333px, 0) rotate(90deg);
        box-shadow: inset 0 0px 30px -5px #a87e50, 0 0 20px -5px;
    }
}

@keyframes send {
    0% {
        transform: translate3d(-100%, 733.33333px, 0) rotateY(-180deg);
        left: 50%;
        margin-left: -275px;
    }

    25% {
        transform: translate3d(-100%, 0, 0) rotateY(-180deg);
        left: 50%;
        margin-left: -275px;
    }

    50% {
        transform: translate3d(-100%, 0, 0) rotateY(-180deg);
        left: 50%;
        margin-left: -275px;
    }

    75% {
        transform: translate3d(0, 0, 0) rotateY(0deg);
        left: 50%;
        margin-left: -275px;
    }

    100% {
        transform: translate3d(0, 0, 0) rotateY(0deg);
        left: 100%;
        margin-left: 20px;
    }
}

@keyframes flap-close {
    0% {
        transform: rotateX(-180deg);
        z-index: -1;
    }

    50% {
        transform: rotateX(0deg);
        z-index: 5;
    }

    100% {
        transform: rotateX(0deg);
        z-index: 5;
    }
}

@keyframes letter-in {
    0% {
        transform: translate3d(0, -733.33333px, 0);
        box-shadow: inset 0 0px 30px -5px #a87e50, 0 0 20px -5px;
    }

    100% {
        transform: translate3d(0, 0, 0);
        box-shadow: inset 0 0px 30px -5px #b08c5b, 0 0 10px -5px;
    }
}

.envelope {
    z-index: 2;
    position: absolute;
    width: 550px;
    height: 366.66667px;
    top: 50%;
    left: 50%;
    margin-top: -183.33333px;
    margin-left: -275px;
    transform-style: preserve-3d;
    transform-origin:   right center;
    transition: transform 1s ease;
    font-family: "Allan", sans-serif;
    color: #1F1F1F;
}

.envelope .front,
.envelope .back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: inset 0 0 30px -5px #a0a0a0;
}

.envelope .front {
    backface-visibility: hidden;
    cursor: pointer;
    display: table;
    box-shadow: inset 0 0 30px -5px #a0a0a0;
}

.envelope .front:focus {
    outline: none;
}

.envelope .front .stamp {
    transform: translate3d(0, 0, 1px);
    position: absolute;
    width: 80px;
    height: 80px;
    right: 10px;
    top: 10px;
    padding: 10px;
    background: radial-gradient(rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 4px, #f6f6df 4px, #f6f6df);
    background-size: 20px 20px;
    background-position: -10px -10px;
}

.envelope .front .stamp:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    border-radius: 2px;
}

.envelope .front .mailme {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.envelope .front .mailme p {
    font-weight: bold;
    margin: 0;
}

.envelope .front .mailme p:first-child {
    font-size: 50px;
    line-height: 50px;
}

.envelope .front .mailme p:last-child {
    font-size: 30px;
}

.envelope .back {
    transform: rotateY(180deg) translate3d(0, 0, 1px);
}

.envelope .back .flap {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.envelope .back .flap:before {
    content: "";
    position: absolute;
    background-color: #FFF;
    box-shadow: inset 0 0 30px -7px #a0a0a0;
}

.envelope .back .top-flap:before,
.envelope .back .bottom-flap:before {
    width: 388.90873px;
    height: 388.90873px;
}

.envelope .back .top-flap {
    z-index: 5;
    transform-origin: top center;
}

.envelope .back .top-flap:before {
    transform-origin: top left;
    transform: rotate(-45deg);
    border-bottom-left-radius: 50px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.envelope .back .bottom-flap {
    z-index: 4;
}

.envelope .back .bottom-flap:before {
    transform-origin: left bottom;
    transform: rotate(45deg) translate(-15px, -15px);
    border-top-left-radius: 100px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
}

.envelope .back .left-flap:before,
.envelope .back .right-flap:before {
    width: 269.27249px;
    height: 269.27249px;
    top: -5px;
}

.envelope .back .left-flap {
    z-index: 3;
}

.envelope .back .left-flap:before {
    transform-origin: top left;
    transform: rotate(45deg);
    border-top-left-radius: 20px;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 5px;
}

.envelope .back .right-flap {
    z-index: 2;
}

.envelope .back .right-flap:before {
    right: 0;
    transform-origin: top right;
    transform: rotate(-45deg);
    border-top-right-radius: 20px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 5px;
}

.envelope .back .letter {
    z-index: 1;
    position: absolute;
    top: 5px;
    left: 10px;
    width: 530px;
    height: 346.66667px;
    background-color: #FFF;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: inset 0 0px 30px -5px #a0a0a0, 0 0 10px -5px;
}

.envelope .back .letter div {
    position: relative;
}

.envelope .back .letter label {
    position: absolute;
    font-size: 16px;
    line-height: 20px;
    opacity: 0;
    top: -15px;
    transition: all 0.1s linear;
    color: #ADADAD;
}

.envelope .back .letter label.show {
    opacity: 1;
    top: -18px;
}

.envelope .back .letter label.focus {
    color: inherit;
}

.envelope .back .letter ::placeholder {
    color: #ADADAD;
}

.envelope .back .letter input[type='text'],
.envelope .back .letter textarea {
    border: none;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    margin: 0;
    background-color: #F6F6DF;
}

.envelope .back .letter input:focus,
.envelope .back .letter textarea:focus {
    outline: none;
}

.envelope .back .letter input,
.envelope .back .letter textarea {
    font-family: "Allan", sans-serif;
    font-size: 20px;
}

.envelope .back .letter input[type='text'] {
    height: 33px;
}

.envelope .back .letter input[type='submit'] {
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 2px;
    padding: 5px 20px;
    background-color: #e7e1bc;
    margin: 0;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
}

.envelope .back .letter input[type='submit']:hover {
    background-color: #DCD0AA;
}

.envelope .back .letter textarea {
    resize: none;
    height: 117.66667px;
}

.envelope.new {
    animation: new 1s forwards;
}

.envelope.open {
    animation: open 3s forwards;
}

.envelope.open .top-flap {
    animation: flap-open 1s 1s forwards;
}

.envelope.open .letter {
    animation: letter-out 1s 2s forwards;
}

.envelope.send {
    animation: send 4s forwards;
}

.envelope.send .top-flap {
    transform: rotateX(-180deg);
    z-index: -1;
    animation: flap-close 1s 1s forwards;
}

.envelope.send .letter {
    animation: letter-in 1s forwards;
}
@media (max-width:767px){
    .envelope.new {
        display: none;
    }
    .mail-wrapper-box{
        display: none;
    }
    .envelope.open {
        display: none;
    }
    .main-animate-container{
        display: none;
    }

}
.gif-tab-buttons {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    column-gap: 15px;
    row-gap: 15px;
    margin-bottom: 15px;
}

.gif-tab-buttons button {
    color: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
    background: #fff;
    border-radius: 20px;
    padding: 5px 25px;
    transition: all .3s ease-in-out;
}

.gif-tab-buttons button.active {
    background: var(--secondary-clr);
    color: #fff;
}

.image-preview-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 25px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 3px dotted var(--secondary-clr);
}

.previiew-image-edit {
    position: relative;
    z-index: 3;
    margin-bottom: 15px;
}

.previiew-image-edit img {
    width: 100%;
}

.p-image-delete {
    position: absolute;
    right: 10px;
    top: 10px;
}

.modal-form textarea.message-area {
    height: 200px;
    width: 100%;
}

.confitee-layout {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    pointer-events: none;
}

.p-image-delete {
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
    color: var(--secondary-clr);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border-radius: 4px;
    border: 1px solid var(--secondary-clr);
}

.social-icons-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 15px 0 0;

}

.social-icons-list li:not(:last-child) {
    margin-right: 10px;
}

.social-icons-list a {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
    background: #fff;
    color: var(--secondary-clr);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.social-icons-list a:hover {
    background: var(--secondary-clr);
    color: #fff;
}



/* edit section  */
.input_name {
    width: 60% !important;
    padding: 10px;
}

.input_name:focus {
    box-shadow: none;
    border: 1px solid var(--secondary-clr);
}

.edit-btn button {
    background: var(--secondary-clr);
    color: #fff;
    border: 0;
    border-radius: 5px;
    padding: 5px 15px;
    display: flex;
}

.edit-btn button:focus {
    border: 0;
}

.edit-btn {
    position: absolute;
    top: 5px;
    right: 21%;
}

.viewboard-area {
    padding: 0 !important;
}

@media (max-width:1024px) {
    .signtext-ctm h1 {
        font-size: 42px;
    }
}

@media (max-width:991px) {
    .signtext-ctm h1 {
        font-size: 36px;
    }
}

@media (max-width:767px) {
    .signtext-ctm h1 {
        font-size: 30px;
    }
    .envelope {
        width: 280px;
        margin-left: -133px;
    }
    .envelope .front, .envelope .back{
        overflow: hidden;
    }
}

.thumb-box{
    position: relative;
    border: 3px solid transparent;
}
.thumb-box img{
    width: 100%;
}
.thumb-box.active{
    border: 3px solid var(--secondary-clr);
}

.pdf-inner {
    background: #fff;
    overflow-y: auto;
    height: 500px;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
}

.download-loading-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.9);  
    text-align: center;
    z-index: 9999999999999999;
}