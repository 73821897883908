/* body {
    color: #fff
} */

.right_conatct_social_icon {
    background: var(--primary-clr);
}

.contact_us {
    background-color: #f5f5f5;
    padding: 150px 0px;
}

.contact_inner {
    background-color: #fff;
    position: relative;
    box-shadow: 5px 4px 2px 0 rgba(0, 0, 0, 0.3);
}

.contact_field {
    padding: 60px 340px 90px 100px;
}

.right_conatct_social_icon {
    height: 100%;
}

.contact_field h3 {
    color: #000;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 10px
}

.contact_field p {
    margin-bottom: 35px;
}

.contact_info_sec {
    position: absolute;
    background-color: var(--secondary-clr);
    right: 0px;
    top: 50%;
    height: auto;
    width: 340px;
    padding: 40px;
    border-radius: 25px 0 0 25px;
    transform: translateY(-50%);
}

.contact_info_sec h4 {
    letter-spacing: 1px;
    padding-bottom: 15px;
    color: #fff
}

.info_single {
    margin: 30px 0px;
}

.info_single i {
    margin-right: 15px;
    color: #fff
}

.info_single span {
    font-size: 14px;
    letter-spacing: 1px;
    color: #fff
}

button.contact_form_submit {
    background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
    border: none;
    color: #fff;
    padding: 10px 15px;
    width: 100%;
    margin-top: 25px;
    border-radius: 35px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 2px;
}

.socil_item_inner {
    list-style: none;
    padding: 0;
}

.socil_item_inner li {
    list-style: none;
}

.socil_item_inner li:not(:last-child) {
    margin-right: 20px;
}

.socil_item_inner li a {
    color: #fff;
    font-size: 20px;
}

.socil_item_inner {
    padding-bottom: 10px;
}

.map_sec {
    padding: 50px 0px;
}

.map_inner h4,
.map_inner p {
    color: #000;
    text-align: center
}

.map_inner p {
    font-size: 13px;
}

.map_bind {
    margin-top: 50px;
    box-shadow: 5px 4px 2px 0 rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

@media (max-width: 991px){
    .contact_info_sec{
        width: 310px;
        padding: 30px;
    }
    .contact_field{
        padding: 60px 235px 70px 50px;
    }
}

@media (max-width: 767px){
    .contact_info_sec{
        width: 100%;
        border-radius: 0;
        padding: 30px;
        position: relative;
        top: 0;
        transform: none;
    }
    .contact_field{
        padding: 30px;
    }
    .contact_us{
        padding: 150px 0px 80px;
    }
}
