

:root {
    --primary-clr: #5F705C;
    --secondary-clr: #F75252;
    --primary-light: #E4F2E8;
    --secondary-light: #FFE4E4;
    --dark-text: #151F35;
    --light-text: #74757E;
}

@font-face {
    font-family: 'Montserrat Alternates';
    src: url('assets/fonts/MontserratAlternates-Thin.woff2') format('woff2'),
        url('assets/fonts/MontserratAlternates-Thin.woff') format('woff'),
        url('assets/fonts/MontserratAlternates-Thin.ttf') format('truetype'),
        url('assets/fonts/MontserratAlternates-Thin.svg#MontserratAlternates-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates';
    src: url('assets/fonts/MontserratAlternates-Regular.woff2') format('woff2'),
        url('assets/fonts/MontserratAlternates-Regular.woff') format('woff'),
        url('assets/fonts/MontserratAlternates-Regular.ttf') format('truetype'),
        url('assets/fonts/MontserratAlternates-Regular.svg#MontserratAlternates-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates';
    src: url('assets/fonts/MontserratAlternates-SemiBold.woff2') format('woff2'),
        url('assets/fonts/MontserratAlternates-SemiBold.woff') format('woff'),
        url('assets/fonts/MontserratAlternates-SemiBold.ttf') format('truetype'),
        url('assets/fonts/MontserratAlternates-SemiBold.svg#MontserratAlternates-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates';
    src: url('assets/fonts/MontserratAlternates-Black.woff2') format('woff2'),
        url('assets/fonts/MontserratAlternates-Black.woff') format('woff'),
        url('assets/fonts/MontserratAlternates-Black.ttf') format('truetype'),
        url('assets/fonts/MontserratAlternates-Black.svg#MontserratAlternates-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates';
    src: url('assets/fonts/MontserratAlternates-ExtraBold.woff2') format('woff2'),
        url('assets/fonts/MontserratAlternates-ExtraBold.woff') format('woff'),
        url('assets/fonts/MontserratAlternates-ExtraBold.ttf') format('truetype'),
        url('assets/fonts/MontserratAlternates-ExtraBold.svg#MontserratAlternates-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Alternates';
    src: url('assets/fonts/MontserratAlternates-Bold.woff2') format('woff2'),
        url('assets/fonts/MontserratAlternates-Bold.woff') format('woff'),
        url('assets/fonts/MontserratAlternates-Bold.ttf') format('truetype'),
        url('assets/fonts/MontserratAlternates-Bold.svg#MontserratAlternates-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('assets/fonts/sourcesans3/SourceSans3-Bold.woff2') format('woff2'),
        url('assets/fonts/sourcesans3/SourceSans3-Bold.woff') format('woff'),
        url('assets/fonts/sourcesans3/SourceSans3-Bold.ttf') format('truetype'),
        url('assets/fonts/sourcesans3/SourceSans3-Bold.svg#SourceSans3-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans 3';
    src: url('assets/fonts/sourcesans3/SourceSans3-Black.woff2') format('woff2'),
        url('assets/fonts/sourcesans3/SourceSans3-Black.woff') format('woff'),
        url('assets/fonts/sourcesans3/SourceSans3-Black.ttf') format('truetype'),
        url('assets/fonts/sourcesans3/SourceSans3-Black.svg#SourceSans3-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans 3';
    src: url('assets/fonts/sourcesans3/SourceSans3-Regular.woff2') format('woff2'),
        url('assets/fonts/sourcesans3/SourceSans3-Regular.woff') format('woff'),
        url('assets/fonts/sourcesans3/SourceSans3-Regular.ttf') format('truetype'),
        url('assets/fonts/sourcesans3/SourceSans3-Regular.svg#SourceSans3-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans 3';
    src: url('assets/fonts/sourcesans3/SourceSans3-ExtraBold.woff2') format('woff2'),
        url('assets/fonts/sourcesans3/SourceSans3-ExtraBold.woff') format('woff'),
        url('assets/fonts/sourcesans3/SourceSans3-ExtraBold.ttf') format('truetype'),
        url('assets/fonts/sourcesans3/SourceSans3-ExtraBold.svg#SourceSans3-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans 3';
    src: url('assets/fonts/sourcesans3/SourceSans3-SemiBold.woff2') format('woff2'),
        url('assets/fonts/sourcesans3/SourceSans3-SemiBold.woff') format('woff'),
        url('assets/fonts/sourcesans3/SourceSans3-SemiBold.ttf') format('truetype'),
        url('assets/fonts/sourcesans3/SourceSans3-SemiBold.svg#SourceSans3-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans 3';
    src: url('assets/fonts/sourcesans3/SourceSans3-ExtraLight.woff2') format('woff2'),
        url('assets/fonts/sourcesans3/SourceSans3-ExtraLight.woff') format('woff'),
        url('assets/fonts/sourcesans3/SourceSans3-ExtraLight.ttf') format('truetype'),
        url('assets/fonts/sourcesans3/SourceSans3-ExtraLight.svg#SourceSans3-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans 3';
    src: url('assets/fonts/sourcesans3/SourceSans3-Medium.woff2') format('woff2'),
        url('assets/fonts/sourcesans3/SourceSans3-Medium.woff') format('woff'),
        url('assets/fonts/sourcesans3/SourceSans3-Medium.ttf') format('truetype'),
        url('assets/fonts/sourcesans3/SourceSans3-Medium.svg#SourceSans3-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Delicious Handrawn';
    src: url('assets/fonts/selectfonts/DeliciousHandrawn-Regular.woff2') format('woff2'),
        url('assets/fonts/selectfonts/DeliciousHandrawn-Regular.woff') format('woff'),
        url('assets/fonts/selectfonts/DeliciousHandrawn-Regular.ttf') format('truetype'),
        url('assets/fonts/selectfonts/DeliciousHandrawn-Regular.svg#DeliciousHandrawn-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Indie Flower';
    src: url('assets/fonts/selectfonts/IndieFlower.woff2') format('woff2'),
        url('assets/fonts/selectfonts/IndieFlower.woff') format('woff'),
        url('assets/fonts/selectfonts/IndieFlower.ttf') format('truetype'),
        url('assets/fonts/selectfonts/IndieFlower.svg#IndieFlower') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fasthand';
    src: url('assets/fonts/selectfonts/Fasthand-Regular.woff2') format('woff2'),
        url('assets/fonts/selectfonts/Fasthand-Regular.woff') format('woff'),
        url('assets/fonts/selectfonts/Fasthand-Regular.ttf') format('truetype'),
        url('assets/fonts/selectfonts/Fasthand-Regular.svg#Fasthand-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pacifico';
    src: url('assets/fonts/selectfonts/Pacifico-Regular.woff2') format('woff2'),
        url('assets/fonts/selectfonts/Pacifico-Regular.woff') format('woff'),
        url('assets/fonts/selectfonts/Pacifico-Regular.ttf') format('truetype'),
        url('assets/fonts/selectfonts/Pacifico-Regular.svg#Pacifico-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kalam';
    src: url('assets/fonts/selectfonts/Kalam-Regular.woff2') format('woff2'),
        url('assets/fonts/selectfonts/Kalam-Regular.woff') format('woff'),
        url('assets/fonts/selectfonts/Kalam-Regular.ttf') format('truetype'),
        url('assets/fonts/selectfonts/Kalam-Regular.svg#Kalam-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/selectfonts/OpenSans-Regular.woff2') format('woff2'),
        url('assets/fonts/selectfonts/OpenSans-Regular.woff') format('woff'),
        url('assets/fonts/selectfonts/OpenSans-Regular.ttf') format('truetype'),
        url('assets/fonts/selectfonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satisfy';
    src: url('assets/fonts/selectfonts/Satisfy-Regular.woff2') format('woff2'),
        url('assets/fonts/selectfonts/Satisfy-Regular.woff') format('woff'),
        url('assets/fonts/selectfonts/Satisfy-Regular.ttf') format('truetype'),
        url('assets/fonts/selectfonts/Satisfy-Regular.svg#Satisfy-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('assets/fonts/selectfonts/PlayfairDisplay-Regular.woff2') format('woff2'),
        url('assets/fonts/selectfonts/PlayfairDisplay-Regular.woff') format('woff'),
        url('assets/fonts/selectfonts/PlayfairDisplay-Regular.ttf') format('truetype'),
        url('assets/fonts/selectfonts/PlayfairDisplay-Regular.svg#PlayfairDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Serif 20pt';
    src: url('assets/fonts/selectfonts/RobotoSerif-20ptRegular.woff2') format('woff2'),
        url('assets/fonts/selectfonts/RobotoSerif-20ptRegular.woff') format('woff'),
        url('assets/fonts/selectfonts/RobotoSerif-20ptRegular.ttf') format('truetype'),
        url('assets/fonts/selectfonts/RobotoSerif-20ptRegular.svg#RobotoSerif-20ptRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('assets/fonts/selectfonts/SourceSerifPro-Regular.woff2') format('woff2'),
        url('assets/fonts/selectfonts/SourceSerifPro-Regular.woff') format('woff'),
        url('assets/fonts/selectfonts/SourceSerifPro-Regular.ttf') format('truetype'),
        url('assets/fonts/selectfonts/SourceSerifPro-Regular.svg#SourceSerifPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alkatra';
    src: url('assets/fonts/selectfonts/Alkatra-Regular.woff2') format('woff2'),
        url('assets/fonts/selectfonts/Alkatra-Regular.woff') format('woff'),
        url('assets/fonts/selectfonts/Alkatra-Regular.ttf') format('truetype'),
        url('assets/fonts/selectfonts/Alkatra-Regular.svg#Alkatra-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dancing Script';
    src: url('assets/fonts/selectfonts/DancingScript-Regular.woff2') format('woff2'),
        url('assets/fonts/selectfonts/DancingScript-Regular.woff') format('woff'),
        url('assets/fonts/selectfonts/DancingScript-Regular.ttf') format('truetype'),
        url('assets/fonts/selectfonts/DancingScript-Regular.svg#DancingScript-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Caveat';
    src: url('assets/fonts/selectfonts/Caveat-Regular.woff2') format('woff2'),
        url('assets/fonts/selectfonts/Caveat-Regular.woff') format('woff'),
        url('assets/fonts/selectfonts/Caveat-Regular.ttf') format('truetype'),
        url('assets/fonts/selectfonts/Caveat-Regular.svg#Caveat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dancing Script';
    src: url('assets/fonts/selectfonts/DancingScript-Regular_1.woff2') format('woff2'),
        url('assets/fonts/selectfonts/DancingScript-Regular_1.woff') format('woff'),
        url('assets/fonts/selectfonts/DancingScript-Regular_1.ttf') format('truetype'),
        url('assets/fonts/selectfonts/DancingScript-Regular_1.svg#DancingScript-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Caveat';
    src: url('assets/fonts/selectfonts/Caveat-Regular_1.woff2') format('woff2'),
        url('assets/fonts/selectfonts/Caveat-Regular_1.woff') format('woff'),
        url('assets/fonts/selectfonts/Caveat-Regular_1.ttf') format('truetype'),
        url('assets/fonts/selectfonts/Caveat-Regular_1.svg#Caveat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Delicious Handrawn';
    src: url('assets/fonts/selectfonts/DeliciousHandrawn-Regular_1.woff2') format('woff2'),
        url('assets/fonts/selectfonts/DeliciousHandrawn-Regular_1.woff') format('woff'),
        url('assets/fonts/selectfonts/DeliciousHandrawn-Regular_1.ttf') format('truetype'),
        url('assets/fonts/selectfonts/DeliciousHandrawn-Regular_1.svg#DeliciousHandrawn-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fasthand';
    src: url('assets/fonts/selectfonts/Fasthand-Regular_1.woff2') format('woff2'),
        url('assets/fonts/selectfonts/Fasthand-Regular_1.woff') format('woff'),
        url('assets/fonts/selectfonts/Fasthand-Regular_1.ttf') format('truetype'),
        url('assets/fonts/selectfonts/Fasthand-Regular_1.svg#Fasthand-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kalam';
    src: url('assets/fonts/selectfonts/Kalam-Regular_1.woff2') format('woff2'),
        url('assets/fonts/selectfonts/Kalam-Regular_1.woff') format('woff'),
        url('assets/fonts/selectfonts/Kalam-Regular_1.ttf') format('truetype'),
        url('assets/fonts/selectfonts/Kalam-Regular_1.svg#Kalam-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Indie Flower';
    src: url('assets/fonts/selectfonts/IndieFlower_1.woff2') format('woff2'),
        url('assets/fonts/selectfonts/IndieFlower_1.woff') format('woff'),
        url('assets/fonts/selectfonts/IndieFlower_1.ttf') format('truetype'),
        url('assets/fonts/selectfonts/IndieFlower_1.svg#IndieFlower') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/selectfonts/OpenSans-Regular_1.woff2') format('woff2'),
        url('assets/fonts/selectfonts/OpenSans-Regular_1.woff') format('woff'),
        url('assets/fonts/selectfonts/OpenSans-Regular_1.ttf') format('truetype'),
        url('assets/fonts/selectfonts/OpenSans-Regular_1.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pacifico';
    src: url('assets/fonts/selectfonts/Pacifico-Regular_1.woff2') format('woff2'),
        url('assets/fonts/selectfonts/Pacifico-Regular_1.woff') format('woff'),
        url('assets/fonts/selectfonts/Pacifico-Regular_1.ttf') format('truetype'),
        url('assets/fonts/selectfonts/Pacifico-Regular_1.svg#Pacifico-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('assets/fonts/selectfonts/PlayfairDisplay-Regular_1.woff2') format('woff2'),
        url('assets/fonts/selectfonts/PlayfairDisplay-Regular_1.woff') format('woff'),
        url('assets/fonts/selectfonts/PlayfairDisplay-Regular_1.ttf') format('truetype'),
        url('assets/fonts/selectfonts/PlayfairDisplay-Regular_1.svg#PlayfairDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satisfy';
    src: url('assets/fonts/selectfonts/Satisfy-Regular_1.woff2') format('woff2'),
        url('assets/fonts/selectfonts/Satisfy-Regular_1.woff') format('woff'),
        url('assets/fonts/selectfonts/Satisfy-Regular_1.ttf') format('truetype'),
        url('assets/fonts/selectfonts/Satisfy-Regular_1.svg#Satisfy-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Serif 20pt';
    src: url('assets/fonts/selectfonts/RobotoSerif-20ptRegular_1.woff2') format('woff2'),
        url('assets/fonts/selectfonts/RobotoSerif-20ptRegular_1.woff') format('woff'),
        url('assets/fonts/selectfonts/RobotoSerif-20ptRegular_1.ttf') format('truetype'),
        url('assets/fonts/selectfonts/RobotoSerif-20ptRegular_1.svg#RobotoSerif-20ptRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alkatra';
    src: url('assets/fonts/selectfonts/Alkatra-Regular_1.woff2') format('woff2'),
        url('assets/fonts/selectfonts/Alkatra-Regular_1.woff') format('woff'),
        url('assets/fonts/selectfonts/Alkatra-Regular_1.ttf') format('truetype'),
        url('assets/fonts/selectfonts/Alkatra-Regular_1.svg#Alkatra-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('assets/fonts/selectfonts/SourceSerifPro-Regular_1.woff2') format('woff2'),
        url('assets/fonts/selectfonts/SourceSerifPro-Regular_1.woff') format('woff'),
        url('assets/fonts/selectfonts/SourceSerifPro-Regular_1.ttf') format('truetype'),
        url('assets/fonts/selectfonts/SourceSerifPro-Regular_1.svg#SourceSerifPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: 'Source Sans 3', sans-serif;
    margin: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


code {
    font-family: 'Source Sans 3', sans-serif;
}
.google-login-btn *{
    font-family: 'Source Sans 3', sans-serif;
    font-weight: 400;
    color: #515151;
}
.facebook-login-btn *{
    font-family: 'Source Sans 3', sans-serif;
    font-weight: 400;
    color: #515151;
}
.facebook-login-btn svg{
    color: #4267b2;
    fill: #4267b2;
}
.centered-text-line {
    position: relative;
    text-align: center;
    text-transform: capitalize;
    z-index: 3;
    margin-bottom: 15px;
}
.centered-text-line:after {
    content: '';
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    height: 1px;
    background: #ccc;
    transform: translateY(-50%);
    z-index: 1;
}
.centered-text-line p {
    position: relative;
    background: #fff;
    padding: 0 15px;
    display: inline-block;
    margin: 0;
    z-index: 3;
}

body .swal2-styled.swal2-confirm{
    background-color: var(--primary-clr);
}
.activate-holder{
    color: black !important;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}
.form-error-message{
    color:red !important;
    font-size: 12px;
    
}

.link-list {
    cursor: pointer;
    padding: 5px 0;
    transition: all .3s ease-in-out;
}
.link-list:hover,.link-list.active {
    color: var(--secondary-clr);
}
a.ft-social-btn{
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
    border-radius: 50%;
    padding: 0;
    text-align: center;
}

.ft-social-list{
    display: flex;
    gap: 15px;
}
a.ft-social-btn:hover{
    color: #fff;
    background: var(--secondary-clr);
}
.payment-reciept-parent{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.reciept{
    border: 1px groove #151F35;
    width: 400px;
    padding: 20px;
    margin: 20px;
}
.reciept > div{
    margin: 5px;
    
}

.popup-btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
}

.checkbox-label{
    font-size: 13px;
    font-weight: 400;
}
.checkbox-label a{
    color: var(--secondary-clr);
}
.invite-email-tab{
    margin-bottom: 20px;
    border: none;
}
.invite-email-tab > li:not(:last-child){
    margin-right: 15px;
}
.invite-email-tab .nav-link{
    background: #fff;
    color: #1a1a1a;
    border-radius: 4px;
}
.invite-email-tab .nav-link.active{
    background: var(--secondary-clr);
    color: #fff;
}

.right-header-menu{
    flex-grow: unset;
}

body .Toastify__toast{
    min-height: 40px;
}

body .Toastify__toast-container{
    min-width: 217px;
    width: auto;
}

body .Toastify__toast-body{
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.eyeview{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

body .swal2-popup {
    width: 23em;
}

body .swal2-title{
    padding: .8em 1.2em 0;
    font-size: 24px;
}

body .tooltip-inner{
    background: #515151;
}
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .bs-tooltip-end .tooltip-arrow::before{
    border-right-color: #515151;
}
.go-to-top-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 50px;
    height: 50px;
    background: var(--primary-clr);
    border-radius: 50%;
    border: none;
    color: #fff;
    font-size: 20px;
    opacity: 0;
    transform: translateY(100%);
    transition: all .3s ease-in-out;
    z-index: 999999;
}
.go-to-top-button.visible {
    opacity: 1;    
    transform: translateY(0);
}
.click-div{
    font-weight: 600;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}
.header-menu .navbar-brand img{
    height: 60px;
}
.onlineer{
    border-bottom: 1px solid var(--secondary-clr);
}
.currency-change{
    width: 200px;
}

textarea.form-control::placeholder,
input::placeholder{
    font-size: 14px;
    font-family: 'Source Sans 3', sans-serif;
}

@media (max-width:767px){
    .go-to-top-button {
        position: fixed;
        right: 10px;
        bottom: 10px;
        width: 40px;
        height: 40px;
        background: var(--secondary-clr);
        border-radius: 50%;
        border: none;
        color: #fff;
        font-size: 16px;
        opacity: 0;
        transform: translateY(100%);
        transition: all .3s ease-in-out;
        z-index: 999999;
    }
}

.credit-area{
    height: 40px;
}