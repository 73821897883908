.pricing-area {
    padding: 150px 0 100px;
}

.pricing-card {
    position: relative;
    padding: 20px;
    background: var(--primary-light);
    border-radius: 10px;
    z-index: 4;
    height: 100%;
}

.bg-light-orange {
    background: var(--secondary-light);
}

.pricing-card h4 {
    font-weight: 600;
    font-size: 30px;
    margin-top: 20px;
    line-height: 20px;
    color: #000;
    text-transform: capitalize !important;
}

.total-price {
    margin-top: 15px;
    font-size: 24px;
    color: #000;
    font-weight: bold;
}

.total-price span {
    position: relative;
    display: inline-block;
    font-size: 20px;
    color: #b5b5b5;
    font-weight: 600;
    margin-left: 10px;
}

.quantity-box {
    width: auto;
    min-width: 50px;
    max-width: 100px;
    text-align: center;
    border: 0;
    outline: none;
}
.quantity-box::-webkit-outer-spin-button,
.quantity-box::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.quantity-box[type=number] {
  -moz-appearance: textfield;
}
.total-price span::after {
    content: "";
    width: 100%;
    height: 2px;
    background: #b5b5b5;
    position: absolute;
    bottom: 13px;
    left: 0;
}

.pricing-card img {
    width: 50px;
    object-fit: contain;
}

.offer-list {
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
}

.offer-list li {
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

.offer-list li:not(:last-child) {
    margin-bottom: 10px;
}

.offer-list li svg {
    font-size: 20px;
    margin-top: 5px;
    margin-right: 15px;
    color: var(--primary-clr);
}

.offer-list li svg.xmark {
    color: red;
    margin-left: 4px;
}

.offer-list li p {
    margin: 0;
    color: #000;
}

.pricing-stamp {
    position: absolute;
    top: -15px;
    right: 20px;
    transition: all .3s ease-in-out;
}

.pricing-price-detail {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    color: #fff;
    align-items: center;
    padding-bottom: 15px;
}

.price-list {
    font-size: 23px;
    font-weight: 500;
    white-space: nowrap;
}

.price-list .price {
    font-size: 28px;
    font-weight: 600;
}

.pricing-line-shape {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.pricing-card:hover .pricing-stamp {
    transform: scale(1.3);
}

/* toggle btn  */
.pricing-toggle-btn .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    border-radius: 50px;
}

.pricing-toggle-btn .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.pricing-toggle-btn .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    background-color: var(--primary-clr);
    -webkit-transition: .4s;
    transition: .4s;
}

.pricing-toggle-btn .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    border-radius: 50px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.pricing-toggle-btn input:checked+.slider {
    background-color: var(--secondary-clr);
}

.pricing-toggle-btn input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

.pricing-toggle-btn input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.qty-input {
    color: #000;
    background: #fff;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
}

.qty-input .product-qty,
.qty-input .qty-count {
    background: transparent;
    color: inherit;
    font-weight: bold;
    font-size: inherit;
    border: none;
    display: inline-block;
    min-width: 0;
    height: 2.5rem;
    line-height: 1;
}

.qty-input .product-qty:focus,
.qty-input .qty-count:focus {
    outline: none;
}

.qty-input .product-qty {
    width: 50px;
    min-width: 0;
    display: inline-block;
    text-align: center;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.qty-input .product-qty::-webkit-outer-spin-button,
.qty-input .product-qty::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.qty-input .qty-count {
    padding: 0;
    cursor: pointer;
    width: 2.5rem;
    font-size: 1.25em;
    text-indent: -100px;
    overflow: hidden;
    position: relative;
}

.qty-input .qty-count:before,
.qty-input .qty-count:after {
    content: "";
    height: 2px;
    width: 10px;
    position: absolute;
    display: block;
    background: #000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.qty-input .qty-count--minus {
    border-right: 1px solid #e2e2e2;
}

.qty-input .qty-count--add {
    border-left: 1px solid #e2e2e2;
}

.qty-input .qty-count--add:after {
    transform: rotate(90deg);
}

.qty-input .qty-count:disabled {
    color: #ccc;
    background: #f2f2f2;
    cursor: not-allowed;
    border-color: transparent;
}

.qty-input .qty-count:disabled:before,
.qty-input .qty-count:disabled:after {
    background: #ccc;
}


.qty-input {
    border-radius: 4px;
    box-shadow: 0 1em 2em -0.9em rgba(0, 0, 0, 0.7);
}

.qty-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
}

/* end toggle btn  */

@media screen and (max-width:575px) {
    .pricing-card {
        margin-bottom: 30px;
    }

    .pricing-card h4 {
        font-size: 24px;
    }

    .pricing-stamp svg {
        width: 80px;
        height: 89px;
    }

    .price-list .price {
        font-size: 22px;
    }

    .offer-list li i {
        font-size: 14px;
        margin-right: 13px;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .pricing-card {
        width: 80%;
        margin: 0 auto 30px;
    }

    .pricing-stamp svg {
        width: 80px;
        height: 89px;
    }

    .pricing-card h4 {
        font-size: 24px;
    }

    .price-list .price {
        font-size: 22px;
    }

    .offer-list li i {
        font-size: 14px;
        margin-right: 13px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .pricing-card {
        margin: 0 auto 30px;
    }

    .pricing-stamp svg {
        width: 80px;
        height: 89px;
    }

    .pricing-card h4 {
        font-size: 24px;
    }

    .pricing-list-card {
        width: 50%;
    }

    .price-list .price {
        font-size: 22px;
    }

    .offer-list li i {
        font-size: 14px;
        margin-right: 13px;
    }

}

@media (min-width:992px) and (max-width:1200px) {

    .pricing-stamp svg {
        width: 80px;
        height: 89px;
    }

    .pricing-card h4 {
        font-size: 22px;
    }

    .price-list .price {
        font-size: 22px;
    }

    .offer-list li i {
        font-size: 14px;
        margin-right: 13px;
    }

}

.pricing-card.selected-version h4{
    font-size: 20px;
    margin-top: 12px;
}
.pricing-card.selected-version .offer-list li p {
    margin: 0;
    font-size: 12px;
    color: #000;
}
.pricing-card.selected-version .offer-list li i{
    font-size: 12px;
}

.pricing-card.selected-version .total-price{
    margin-top: 0;
}